/* Header */
.nav-background {
  background-color: var(--darker-grey);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}

.nav-wrapper {
  max-width: 65rem;
  min-height: 2.5rem;
  margin: 0 auto;
  padding: 0.4rem 2rem;

}

.logo {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

header h1 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 550;
}

.smallPixieLogo {
  max-width: 0.9rem;
  max-height: 0.9rem;
}

/* Sections */
section.light {
  background-color: var(--mint-cream);
  color: var(--prussian-blue);
}

section.dark {
  background-color: var(--wintergreen);
  color: var(--mint-cream);
}

.steps {
  display: flex;
  justify-content: center;
}

.steps .step, .estimated-velocity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem;
  background-color: var(--cambridge-green);
  margin: 1rem;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  color: var(--prussian-blue);
  line-height: 2rem;
  font-weight: bold;
  border: 2px solid var(--mint-cream);
}

.step .number, .estimated-velocity {
  font-size: 2.5rem;
  font-weight: bold;
}

/* Fonts */
.title {
  font-size: 3.5rem;
  color: white;
}

.subtitle {
  font-size: 3rem;
}

.small-subtitle {
  font-size: 2rem;
}

section.dark .small-subtitle {
  color: white;
}

section.light .small-subtitle {
  color: var(--prussian-blue);
}

.text-bold {
  font-weight: bold;
}

.text-orange {
  color: var(--sizzling-red);
}

.gameover p {
  font-size: 1.25rem;
}

/* Button variables */
.button {
  --size: 60px;
}

input[type='text'], input[type='number'] {
  /* Override default styles */
  background: none;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  margin: 0rem 1rem;
  padding: 0.5rem;
}

section.light input[type='text'],
section.light input[type='number'] {
  border: 1px solid var(--prussian-blue);
  color: var(--prussian-blue);
}

section.dark input[type='text'],
section.dark input[type='number']{
  border: 1px solid white;
  color: white;
}

input[type='number']{
  width: 2.5rem;
}

section.light input[type='number']:focus,
section.light input[type='text']:focus {
  outline: 2px solid var(--prussian-blue);
}

section.dark input[type='number']:focus,
section.dark input[type='text']:focus {
  outline: 2px solid white;
}

/* Hide the arrows in the number input  */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.button, input[type='file'] + label {
  display: inline-block;
  margin: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  color: white;
  background: var(--prussian-blue);
  border-radius: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

section.light .button {
  box-shadow: 3px 3px 3px var(--cambridge-green);
}

.button:hover,
input[type='file'] + label:hover,
input[type='file']:focus + label {
  transform: translateY(2px);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.button:disabled {
  cursor: not-allowed;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 0 auto;
}

/* Round button styles */
.button--round {
  padding: 0;
  height: var(--size);
  width: var(--size);
  font-size: 40px;
  line-height: 0;
  border: none;
  border-radius: 50%;
  background: white;
  box-shadow: 9px 9px 0px rgba(0, 0, 0, 0.2);
}

.button--round:hover, .button--round:focus {
  outline: none;
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(3px);
}

.button--round:active {
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(6px);
}

.button--tick {
  color: var(--prussian-blue);
}

.button--cross {
  color: var(--sizzling-red);
}

.end-game-buttons {
  position: absolute;
  right: 0;
}

.more-padding {
  padding: 3rem;
}

#accepted-stories {
  text-align: left;
}

.estimated-velocity {
  margin: 0 auto;
}

/* Footer */
.footer-background {
  background-color: var(--footer-grey);
}

.footer-wrapper {
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.builtWithLove {
  max-width: 15rem;
  margin-top: 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 3rem;
}

.products-wrapper h5, .products-wrapper a {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6rem;
  display: block;
  text-decoration: none;
  color: white;
  margin: 0.125rem 0.83rem 0.125rem 0;
}

.products-wrapper h5 {
  padding: 0 0.7rem 0.188rem;
}

.products-wrapper a {
  font-weight: normal;
  color: hsla(0, 0%, 100%, .6);
  padding: 0.188rem 0.7rem;
}

.products-wrapper a:hover {
  color: white;
}