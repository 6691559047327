:root {
  --prussian-blue: #003249;
  --mint-cream: #F6FFF8;
  --cambridge-green: #A4C3B2;
  --wintergreen: #6B9080;
  --sizzling-red: #FF5A5F;
  --light-grey: #e8e8e8;
  --grey: #A9A9A9;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

header {
  background-color: white;
}

main {
  background-color: var(--mint-cream);
  text-align: center;
}

section {
  padding: 2rem 6rem 4rem 6rem;
}

footer {
  background-color: white;
  text-align: left;
}

a {
  color: var(--sizzling-red);
  font-weight: bold;
}