/* Card variables */
.card {
  --height: 250px;
  --width: 160px;
  --background: white;
  --padding: 10px;
  --symbol-size: 40px;
  --small-scale: 0.7;
}

.card {
  position: relative;
  padding: var(--padding);
  margin: 10px;

  width: var(--width);
  height: var(--height);

  /* Center the story */
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;

  background-color: var(--background);
  color: var(--prussian-blue);

  border: none;
  border-radius: 10px;
  box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.2);
}

/* Hearts */
.card::after, .card::before {
  content: '♦';
  position: absolute;
  color: var(--sizzling-red);
  font-size: var(--symbol-size);
  margin: 0 var(--padding);
}

/* Position top heart in the top left corner */
.card::before {
  top: 0;
  left: 0;
}

/* Position bottom heart in the bottom right corner, upside down */
.card::after {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.card--small {
  width: calc(var(--width) * var(--small-scale));
  height: calc(var(--height) * var(--small-scale));
  font-size: 1rem;
}

.card--small::after, .card--small::before {
  font-size: calc(var(--symbol-size) * var(--small-scale));
}
