
:root {
  --footer-grey: #292f36;
  --darker-grey: #272f36;
  --dark-grey: #4a535c;
  --mid-grey: #636a70;
  --light-grey: #e1e7ea;
  --lighter-grey: #f0f5f7;
  --mid-grey: #848b92;
  --orange: #edb53b;
  --red: #db5461;
  --green: #3ab28d;
  --light-blue: #e9f1f7;
}

h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 2em;
  text-align: center;
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

.whiteBackground{
  background-color: #fff;
}

.wrapper {
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
  padding: 3em 10px;
}

img.downCheveron {
  margin-bottom: 2em;
  width: 96px;
}

img {
  max-width: 100%;
}

.projectTitle {
  color: #fff;
  text-align: center;
  font-weight: lighter;
}

@media only screen and (min-width: 480px) {
  .projectTitle h2 {
    font-size: 300%;
  }
}

.projectTitle span {
  font-weight: 600;
}

.splashButton {
  margin-bottom: 40px;
  margin-right: 10px;

}

.homeSplashFade {
  background-color: var(--darker-grey);
  background: linear-gradient(90deg, var(--darker-grey), var(--dark-grey) 100%);
}

.buttonSection {
  margin-top: 1em;
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
}

.heroImage {
  display: none;
}

.keyBenefits {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.benefit {
  width: 100%;
  margin: 1em;
  margin-bottom: 2em;
}

.benefit p, .sectionElement p {
  line-height: 1.5;
  font-weight: 400;
}

.benefitImage {
  max-width: 200px;
}

.lightBlueBackground {
  background-color: var(--light-blue);
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.section .sectionElement {
  padding: 0 30px;
}

.sectionElement {
  text-align: left;
}

.sectionElement p {
  color: var(--mid-grey);
  font-size: 18px;
}

.buttonWrapper {
  margin-top: 1rem;
  margin-right: 5px;
}

.button {
  margin: 0px;
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.button:hover {
  border-color: var(--orange);
  color: var(--orange);
  background: none;
}

.orangeButton {
  color: #fff;
  padding: 14px 38px;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  border-radius: 0;
  text-transform: none;
}

.imageGroup {
  position: relative;
}

.featureImage {
  display: none;
}

.screenshot {
  max-width: 450px;
}

.blogPageScreenshot {
  width: 300px;
}

.blogPostsDescription {
  width: auto;
}

.blogScreenshot {
  position: absolute;
  top: 36%;
  left: 46%;
  width: 280px;
}

.pixieContainer {
  padding: 80px 0 100px;
}

.pixieWrapper.wrapper {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.pixieBackground {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;
}

.pixieBackground img {
  opacity: .35;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  min-height: 100%;
  height: auto;
  max-width: 100%;
}

.square {
  display: none;
  position: absolute;
}

.squareSmall {
  height: 32px;
  width: 32px;
}

.squareLarge {
  width: 72px;
  height: 72px;
}

.squareLeftRed {
  top: 148px;
  left: 112px;
  background-color: var(--red);
}

.squareLeftGreen {
  top: 180px;
  left: 40px;
  background-color: var(--green);
}

.squareRightRed {
  top: 80px;
  right: 100px;
  background-color: var(--red);
}

.squareRightGreen {
  top: 180px;
  right: 200px;
  background-color: var(--green);
}

.pixieBlock {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  z-index: 1;
}

.pixieLabsTitle {
  color: #fff;
  font-weight: lighter;
  margin-top: 36px;
  max-width: 600px;
  text-align: center;
}

.pixieLabsTitle span {
  font-weight: 500;
}

.redUnderline {
  border-bottom: 4px solid var(--red);
}

.greenUnderline {
  border-bottom: 4px solid var(--green);
}

.whiteButton {
  color: #000;
  padding: 14px 38px;
  font-size: 16px;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #272f36;
  border-color: #fff;
  border-radius: 0;
  text-transform: none;
}

.whiteButton:hover {
  border-color: var(--green);
  color: var(--green);
  background: none;
}

.rightChevron {
  height: 12px;
  margin-left: 12px;
}

@media only screen and (min-width: 736px) {
  .keyBenefits {
    flex-wrap: nowrap;
    text-align: center;
  }

  .benefit {
    width: 33.3%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    justify-content: center;
    padding: 6em 10px;
  }

  .leftHero {
    display:flex;
    flex-direction: column;
    text-align: left;
    margin-right: 20px;
  }

  .heroImage {
    display: block;
    margin: auto 0;
  }

  .projectTitle {
    text-align: left;
  }
  
  .buttonSection {
    justify-content: start;
  }

  img.downCheveron {
    margin-top: 4em;
  }

  .featureImage {
    display: block;
  }

  .blogPostsDescription {
    width: 400px;
  }

  .square {
    display: block;
  }  
}