.projectContainer {
  display: flex;
  justify-content: center;
}

.projectContainer ul {
  list-style-type: none;
  padding: 0;
}

.projectContainer ul li {
  margin-bottom: 1rem;
}

.projectContainer h2 {
  color: var(--prussian-blue);
}

.projects, .stories {
  background-color: white;
  margin: 1rem;
  border-radius: 2rem;
  visibility: visible;
  padding: 1rem 2.5rem;
  border: 1px solid var(--cambridge-green);
}

.projects {
  width: 16rem;
}

.stories {
  width: 24rem;
}

.stories.hidden {
  visibility: hidden;
}

.stories ul {
  text-align: left;
}

.stories h3 {
  text-align: left;
}

.stories .story {
  display: flex;
}

.stories .story div {
  margin-right: 1rem;
}