/* Indicator variables */
.indicators {
  --size: 80px;
}

.indicators {
  position: absolute;
}

.indicators-badge {
  margin-left: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  font-size: 1rem;
  font-weight: bold;
  background-color: var(--light-grey);
  color: var(--grey);
  border-radius: 50%;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: -20px;
}

.indicators-badge .lock {
  display: block;
  position: absolute;
  top: 8px;
  width: 100%;
  text-align: center;
  opacity: 0.6;
}

.indicators-badge:last-child {
  background-color: white;
  color: var(--prussian-blue);
}

.indicators-badge:first-child {
  margin-top: 1rem;
}

.indicator-game-over {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}

.indicator-game-over p {
  font-weight: bold;
  font-size: 1rem;
}

.indicator-game-over .indicators-badge {
  background-color: white;
  color: var(--prussian-blue);
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 10px;
}